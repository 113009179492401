<template>
    <div class="job-pending-dialog" data-testid="job-pending-dialog">
        <frm-spinner />
        <span v-if="pendingJobMessage" class="message">
            <frm-icon name="info" />
            {{ pendingJobMessage }}
        </span>
    </div>
</template>

<script>
import store from '../store';

export default {
    computed: {
        pendingJobMessage() {
            return store.state.core.pendingJobMessage;
        },
    },
};
</script>

<style lang="scss" scoped>
    .job-pending-dialog {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 200px;
        padding: 20px;

        .message {
            align-items: center;
            border-radius: 5px;
            display: inline-flex;
            font-size: 1rem;
            margin-bottom: 10px;
            margin-top: 20px;
            padding: 5px 10px;
            text-align: center;

            .frm-icon {
                height: rhythm(2);
                margin-right: 10px;
                width: rhythm(2);
            }
        }
    }
</style>
