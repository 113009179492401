import './polyfills';
import Vue from 'vue';
import i18n from './i18n';
import store from './store';
import ModalManager from './services/modalManager';
import TextareaAutosize from 'vue-textarea-autosize';

/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the body of the page. From here, you may begin adding components to
 * the application, or feel free to tweak this setup for your needs.
 */

Vue.use(require('./plugins/Shortcut'));
Vue.use(TextareaAutosize);

Vue.directive('loading', {});
Vue.directive('required-checkbox', require('./directives/RequiredCheckbox'));

Vue.component('frm-clinimetrics-button', require('./components/ClinimetricsButton.vue').default);
Vue.component('frm-clinimetrics-overview-chart', () => import(/* webpackChunkName: "clinimetrics-overview" */ './components/ClinimetricsOverviewChart.vue'));
Vue.component('frm-clinimetrics-overview-page', () => import(/* webpackChunkName: "clinimetrics-overview" */ './components/ClinimetricsOverviewPage.vue'));
Vue.component('frm-create-button', require('./components/CreateButton.vue').default);
Vue.component('frm-cov-check', require('./components/CovCheck.vue').default);
Vue.component('frm-dashboard', require('./components/Dashboard.vue').default);
Vue.component('frm-deceased-icon', require('./components/DeceasedIcon.vue').default);
Vue.component('frm-dropdown', require('./components/Dropdown.vue').default);
Vue.component('frm-edit-button', require('./components/EditButton.vue').default);
Vue.component('frm-expandable-content', require('./components/ExpandableContent.vue').default);
Vue.component('frm-form', require('./components/Form.vue').default);
Vue.component('frm-form-anamnesis-anamnesis', require('./components/Forms/Anamnesis/Anamnesis.vue').default);
Vue.component('frm-form-anamnesis-conclusion', require('./components/Forms/Anamnesis/Conclusion.vue').default);
Vue.component('frm-form-anamnesis-default', require('./components/Forms/Anamnesis/Default.vue').default);
Vue.component('frm-form-anamnesis-examination-item', require('./components/Forms/Anamnesis/ExaminationItem.vue').default);
Vue.component('frm-form-anamnesis-registration', require('./components/Forms/Anamnesis/Registration.vue').default);
Vue.component('frm-form-anamnesis-screening', require('./components/Forms/Anamnesis/Screening.vue').default);
Vue.component('frm-form-anamnesis-treatment-plan', require('./components/Forms/Anamnesis/TreatmentPlan.vue').default);
Vue.component('frm-form-appointment', require('./components/Forms/Appointment.vue').default);
Vue.component('frm-form-base-examination-items', require('./components/Forms/BaseExaminationItems.vue').default);
Vue.component('frm-form-caregivers', require('./components/Forms/Caregivers.vue').default);
Vue.component('frm-form-clinimetrics-aims', require('./components/Forms/Clinimetrics/Aims.vue').default);
Vue.component('frm-form-clinimetrics-default', require('./components/Forms/Clinimetrics/Default.vue').default);
Vue.component('frm-form-clinimetrics-hands', require('./components/Forms/Clinimetrics/Hands.vue').default);
Vue.component('frm-form-clinimetrics-plagio', require('./components/Forms/Clinimetrics/Plagio.vue').default);
Vue.component('frm-form-clinimetrics-popq', require('./components/Forms/Clinimetrics/Popq.vue').default);
Vue.component('frm-form-contact-moment', require('./components/Forms/ContactMoment.vue').default);
Vue.component('frm-form-contact-request', require('./components/Forms/ContactRequest.vue').default);
Vue.component('frm-form-complaint', require('./components/Forms/Complaint.vue').default);
Vue.component('frm-form-complaint-indication-items', require('./components/Forms/ComplaintIndicationItems.vue').default);
Vue.component('frm-form-employee', require('./components/Forms/Employee.vue').default);
Vue.component('frm-form-external-interface-institute', require('./components/Forms/ExternalInterfaceInstitute.vue').default);
Vue.component('frm-form-await-job-on-submit', require('./components/Forms/AwaitJobOnSubmit.vue').default);
Vue.component('frm-form-group', require('./components/FormGroup.vue').default);
Vue.component('frm-form-institute', require('./components/Forms/Institute.vue').default);
Vue.component('frm-form-insurer-contracts-import', require('./components/Forms/InsurerContractsImport.vue').default);
Vue.component('frm-form-patient-general', require('./components/Forms/Patient/General.vue').default);
Vue.component('frm-form-permissions', require('./components/Forms/Permissions.vue').default);
Vue.component('frm-form-questionnaire-invite', require('./components/Forms/QuestionnaireInvite.vue').default);
Vue.component('frm-form-reports', require('./components/Forms/Reports.vue').default);
Vue.component('frm-form-send-zorgmail', require('./components/Forms/SendZorgmail.vue').default);
Vue.component('frm-form-shopping-basket', require('./components/Forms/Patient/ShoppingBasket.vue').default);
Vue.component('frm-form-subgoals', require('./components/Forms/Subgoals.vue').default);
Vue.component('frm-form-templates', require('./components/Forms/Templates.vue').default);
Vue.component('frm-form-treatment-plan-phase-items', require('./components/Forms/TreatmentPlanPhaseItems.vue').default);
Vue.component('frm-form-treatment-plans', require('./components/Forms/TreatmentPlans.vue').default);
Vue.component('frm-form-year-transition', require('./components/Forms/YearTransition.vue').default);
Vue.component('frm-form-vat', require('./components/Forms/Vat.vue').default);
Vue.component('frm-form-invoicing-adjustment', require('./components/Forms/InvoicingAdjustment.vue').default);
Vue.component('frm-greeting', require('./components/Greeting.vue').default);
Vue.component('frm-icon', require('./components/Icon.vue').default);
Vue.component('frm-indication-history-actions', require('./components/IndicationHistoryActions.vue').default);
Vue.component('frm-inline-child-overview', require('./components/InlineChildOverview.vue').default);
Vue.component('frm-inline-child-overview-wrapper', require('./components/InlineChildOverviewWrapper.vue').default);
Vue.component('frm-inline-child-overview-anamnesis-clinimetrics-presence', require('./components/InlineChildOverviews/Anamnesis/ClinimetricsPresence.vue').default);
Vue.component('frm-inline-child-overview-clinimetrics-subscores-conclusions', require('./components/InlineChildOverviews/ClinimetricsSubscoresConclusions.vue').default);
Vue.component('frm-inline-child-overview-patient-permissions', require('./components/InlineChildOverviews/PatientPermissions.vue').default);
Vue.component('frm-inline-child-overview-roles', require('./components/InlineChildOverviews/Roles.vue').default);
Vue.component('frm-modal-auto-open', require('./components/ModalAutoOpen.vue').default);
Vue.component('frm-modal-button', require('./components/ModalButton.vue').default);
Vue.component('frm-modal-component', require('./components/ModalComponent.vue').default);
Vue.component('frm-open-patient-in-physitrack-button', () => import(/* webpackChunkName: "overview" */ './components/OpenPatientInPhysitrackButton.vue'));
Vue.component('frm-overview', () => import(/* webpackChunkName: "overview" */ './components/Overview.vue'));
Vue.component('frm-overview-actions-dropdown', () => import(/* webpackChunkName: "overview" */ './components/OverviewActions/Dropdown.vue'));
Vue.component('frm-overview-online-standard-form-field-text', () => import(/* webpackChunkName: "overview" */ './components/Overviews/OnlineStandardFormFieldText.vue'));
Vue.component('frm-overview-standard-form-field-text', () => import(/* webpackChunkName: "overview" */ './components/Overviews/StandardFormFieldText.vue'));
Vue.component('frm-overview-tree', () => import(/* webpackChunkName: "overview" */ './components/OverviewTree.vue'));
Vue.component('frm-overview-table-billables', () => import(/* webpackChunkName: "overview" */ './components/OverviewTables/Billables.vue'));
Vue.component('frm-overview-table-invoice-lines', () => import(/* webpackChunkName: "overview" */ './components/OverviewTables/InvoiceLines.vue'));
Vue.component('frm-overview-table-nonbillables', () => import(/* webpackChunkName: "overview" */ './components/OverviewTables/Nonbillables.vue'));
Vue.component('frm-overview-table-return-information-lines', () => import(/* webpackChunkName: "overview" */ './components/OverviewTables/ReturnInformationLines.vue'));
Vue.component('frm-panel', require('./components/Panel.vue').default);
Vue.component('frm-patient-request-personal-data', require('./components/PatientRequestPersonalData.vue').default);
Vue.component('frm-patient-search', require('./components/PatientSearch.vue').default);
Vue.component('frm-patient-card', require('./components/PatientCard.vue').default);
Vue.component('frm-patient-search-teaser', require('./components/PatientSearch/Teaser.vue').default);
Vue.component('frm-prems-notification', require('./components/PremsNotification.vue').default);
Vue.component('frm-primary-nav', require('./components/PrimaryNav.vue').default);
Vue.component('frm-questionnaire-invite-select-clinimetrics', require('./components/QuestionnaireInviteSelectClinimetrics.vue').default);
Vue.component('frm-refresh-button', require('./components/RefreshButton.vue').default);
Vue.component('frm-return-information', require('./components/ReturnInformation.vue').default);
Vue.component('frm-institute-redirector', require('./components/InstituteRedirector.vue').default);
Vue.component('frm-scrollable-table-wrapper', require('./components/ScrollableTableWrapper.vue').default);
Vue.component('frm-schedule', () => import(/* webpackChunkName: "schedule" */ './components/Schedule.vue'));
Vue.component('frm-sidebar', require('./components/Sidebar.vue').default);
Vue.component('frm-online-standard-form-field-text-overview-filter', require('./components/OnlineStandardFormFieldTextOverviewFilter.vue').default);
Vue.component('frm-standard-form-field-text-overview-filter', require('./components/StandardFormFieldTextOverviewFilter.vue').default);
Vue.component('frm-spinner', require('./components/Spinner.vue').default);
Vue.component('frm-tabbed-content', require('./components/TabbedContent.vue').default);
Vue.component('frm-tabbed-content-tab', require('./components/TabbedContentTab.vue').default);
Vue.component('frm-zorgmail-overview', () => import(/* webpackChunkName: "zorgmail" */ './components/ZorgMailOverview.vue'));

// Dashboards
Vue.component('frm-dashboard-fysioroadmap', require('./components/Dashboards/FysioRoadmap.vue').default);
Vue.component('frm-dashboard-messaging', require('./components/Dashboards/Messaging.vue').default);
Vue.component('frm-dashboard-patient', require('./components/Dashboards/Patient.vue').default);

// Calendar
Vue.component('frm-calendar', () => import(/* webpackChunkName: "calendar" */ './components/Calendar.vue'));

// Inputs
Vue.component('frm-input-ajax-select', require('./components/Inputs/AjaxSelect.vue').default);
Vue.component('frm-input-anatomical-drawing', () => import(/* webpackChunkName: "input-anatomical-drawing" */ './components/Inputs/Fysioroadmap/AnatomicalDrawing.vue'));
Vue.component('frm-input-auto-numeric', () => import(/* webpackChunkName: "input-auto-numeric" */ './components/Inputs/AutoNumeric.vue'));
Vue.component('frm-input-avatar', require('./components/Inputs/Avatar.vue').default);
Vue.component('frm-input-cascader', () => import(/* webpackChunkName: "input-cascader" */ './components/Inputs/Cascader.vue'));
Vue.component('frm-input-color-picker', () => import(/* webpackChunkName: "input-color-picker" */ './components/Inputs/ColorPicker.vue'));
Vue.component('frm-input-currency', () => import(/* webpackChunkName: "input-auto-numeric" */ './components/Inputs/Currency.vue'));
Vue.component('frm-input-date-picker', () => import(/* webpackChunkName: "input-date-picker" */ './components/Inputs/DatePicker.vue'));
Vue.component('frm-input-date-range-picker', () => import(/* webpackChunkName: "input-date-picker" */ './components/Inputs/DateRangePicker.vue'));
Vue.component('frm-input-date-time-picker', () => import(/* webpackChunkName: "input-date-picker" */ './components/Inputs/DateTimePicker.vue'));
Vue.component('frm-input-diagnosis-code', require('./components/Inputs/DiagnosisCode.vue').default);
Vue.component('frm-input-editor', () => import(/* webpackChunkName: "input-editor" */ './components/Inputs/Editor.vue'));
Vue.component('frm-input-file', require('./components/Inputs/FileInput.vue').default);
Vue.component('frm-input-institute-select', () => import(/* webpackChunkName: "input-institute-select" */ './components/Inputs/InstituteSelect.vue'));
Vue.component('frm-input-multi-select', require('./components/Inputs/MultiSelect.vue').default);
Vue.component('frm-input-multiple-performance-codes-select', require('./components/Inputs/MultiplePerformanceCodesSelect.vue').default);
Vue.component('frm-input-note-textarea', require('./components/Inputs/NoteTextarea.vue').default);
Vue.component('frm-input-permyriad', () => import(/* webpackChunkName: "input-auto-numeric" */ './components/Inputs/Permyriad.vue'));
Vue.component('frm-input-range', () => import(/* webpackChunkName: "input-range" */ './components/Inputs/Range.vue'));
Vue.component('frm-input-red-flags-select', require('./components/Inputs/Fysioroadmap/RedFlagsSelect.vue').default);
Vue.component('frm-input-select', require('./components/Inputs/Select.vue').default);
Vue.component('frm-input-smart-ajax-select', () => import(/* webpackChunkName: "input-smart-select" */ './components/Inputs/Smart/AjaxSelect.vue'));
Vue.component('frm-input-smart-ajax-text', require('./components/Inputs/Smart/AjaxText.vue').default);
Vue.component('frm-input-smart-ajax-textarea', require('./components/Inputs/Smart/AjaxTextarea.vue').default);
Vue.component('frm-input-smart-data-select', () => import(/* webpackChunkName: "input-smart-select" */ './components/Inputs/Smart/DataSelect.vue'));
Vue.component('frm-input-smart-data-text', require('./components/Inputs/Smart/DataText.vue').default);
Vue.component('frm-input-smart-data-textarea', require('./components/Inputs/Smart/DataTextarea.vue').default);
Vue.component('frm-input-smart-element-select', () => import(/* webpackChunkName: "input-smart-element-select" */ './components/Inputs/SmartElementSelect.vue'));
Vue.component('frm-input-smart-server-select', () => import(/* webpackChunkName: "input-smart-select" */ './components/Inputs/Smart/ServerSelect.vue'));
Vue.component('frm-input-time-picker', () => import(/* webpackChunkName: "input-time-picker" */ './components/Inputs/TimePicker.vue'));

Vue.component('frm-input-invoiced-consults-periods', () => import('./components/Inputs/Fysioroadmap/InvoicedConsultsPeriods.vue'));

// Clinimetrics
Vue.component('frm-clinimetrics-aims-image-checkboxes', () => import(/* webpackChunkName: "clinimetrics-aims" */ './components/Clinimetrics/AimsImageCheckboxes.vue'));
Vue.component('frm-clinimetrics-aims-scoring-graph', () => import(/* webpackChunkName: "clinimetrics-aims" */ './components/Clinimetrics/AimsScoringGraph.vue'));
Vue.component('frm-clinimetrics-plagio', () => import(/* webpackChunkName: "clinimetrics-plagio" */ './components/Clinimetrics/Plagio.vue'));
Vue.component('frm-clinimetrics-popq', () => import(/* webpackChunkName: "clinimetrics-popq" */ './components/Clinimetrics/POPQ.vue'));
Vue.component('frm-input-clinimetrics-hand-gonio', () => import(/* webpackChunkName: "input-clinimetrics-hand-gonio" */ './components/Clinimetrics/Hand/HandGonio.vue'));
Vue.component('frm-input-clinimetrics-hand-volume', () => import(/* webpackChunkName: "input-clinimetrics-hand-volume" */ './components/Clinimetrics/Hand/HandVolume.vue'));

// Statistics
Vue.component('frm-statistics-overview-practice-information', () => import(/* webpackChunkName: "statistics-overviews" */ './components/Statistics/Overviews/PracticeInformation'));
Vue.component('frm-statistics-overview-prems', () => import(/* webpackChunkName: "statistics-overviews" */ './components/Statistics/Overviews/Prems'));
Vue.component('frm-statistics-overview-revenue-or-remuneration', () => import(/* webpackChunkName: "statistics-overviews" */ './components/Statistics/Overviews/RevenueOrRemuneration/RevenueOrRemuneration.vue'));
Vue.component('frm-statistics-overview-calendar', () => import(/* webpackChunkName: "statistics-overviews" */ './components/Statistics/Overviews/CalendarOverview/CalendarOverview.vue'));

// Don't mount the app if there is no container
if (document.getElementById('js-app') !== null) {
    window.vm = new Vue({
        el: '#js-app',
        i18n,
        store,
        data() {
            return {
                ModalManager: ModalManager,
            };
        },
        computed: {
            requestPoolPending() {
                return this.$store.state.core.requestPoolPending;
            },
        },
        watch: {
            requestPoolPending(pending) {
                window.document.documentElement.toggleAttribute('data-request-pool-pending', pending);
            },
        },
        created() {
            if (!window.location.search.includes('modal=1') && document.getElementById('js-header')) {
                this.$store.dispatch('counters/updateAll');
            }
        },
        methods: {
            cancel() {
                // no-op, present for cancel button
            },
            reload() {
                window.location.reload();
            },
        },
    });
}
