import OverviewTableColumnRenderer from './OverviewTableColumnRenderer';
import iconRenderer from './iconRenderer';

export default class OverviewTableSubTableRenderer {
    /**
     * @param {Array} columns
     * @param {Array} data
     * @param {Boolean} showContextMenuButton
     *
     * @return {Element}
     */
    static render(columns, data, showContextMenuButton = false) {
        const div = document.createElement('div');
        div.className = 'overview__table';

        const table = document.createElement('table');
        div.appendChild(table);
        table.className = 'table';

        table.appendChild(this.getTableHeader(columns, showContextMenuButton));
        table.appendChild(this.getTableBody(columns, data, showContextMenuButton));

        return div;
    }

    /**
     * @param {Array} columns
     * @param {Boolean} showContextMenuButton
     *
     * @return {Element}
     */
    static getTableHeader(columns, showContextMenuButton) {
        const header = document.createElement('thead');
        const tr = header.insertRow();

        columns.forEach((column) => {
            const th = document.createElement('th');
            tr.appendChild(th);

            if (column.options.width) {
                th.style = `width: ${column.options.width}${/^\d+$/.test(column.options.width) ? 'px' : ''};`;
            }

            const span = document.createElement('span');
            th.appendChild(span);
            span.className = 'table__th-wrapper';
            span.innerText = column.label;
        });

        if (showContextMenuButton) {
            const th = document.createElement('th');
            tr.appendChild(th);
            th.className = 'table__context-menu-button-col';

            const span = document.createElement('span');
            th.appendChild(span);

            span.className = 'table__th-wrapper';
        }

        return header;
    }

    /**
     * @param {Array} columns
     * @param {Array} data
     * @param {Boolean} showContextMenuButton
     *
     * @return {Element}
     */
    static getTableBody(columns, data, showContextMenuButton = false) {
        const body = document.createElement('tbody');

        data.forEach((row, index) => {
            const tr = body.insertRow();
            tr.dataset.is_subtable_row = '1';
            tr.dataset.subtable_row_data = JSON.stringify(row);

            if (index % 2 === 1) {
                tr.className = 'even';
            } else {
                tr.className = 'odd';
            }

            columns.forEach((column) => {
                const td = document.createElement('td');
                tr.appendChild(td);
                td.className = `overview__column overview__column--${column.type}`;
                td.innerHTML = OverviewTableColumnRenderer.render(column.type, row[column.data], column.options);
            });

            if (showContextMenuButton) {
                const td = document.createElement('td');
                tr.appendChild(td);
                td.className = 'overview__column--actions-button';

                const icon = iconRenderer('dots', 'button__icon');
                const button = document.createElement('button');
                button.type = 'button';
                button.className = 'button button--icon button--secondary js-overview-context-menu-button';

                button.innerHTML = icon;

                td.appendChild(button);
            }
        });

        return body;
    }
}
