import axios from 'axios';
import ModalManager from './modalManager';
import DialogManager from './DialogManager';

export default async function invoiceAndPrint(id, type) {
    let response;

    try {
        response = await axios.post('/financial/billables/invoice', {
            id,
            revenue_resource_type: type,
        });
    } catch (err) {
        return DialogManager.alert(err.response?.data?.message || this.$t('base.errors.server'));
    }

    return ModalManager.openModal(`financial/invoices/print-template?id=${response.data.ids}&institute_id=${response.data.institute_id}&modal=1`, undefined, undefined, 'full').then(data => data.job);
}
