export const setRequestPoolPending = (state, requestPoolPending) => {
    state.requestPoolPending = requestPoolPending;
};

export const setPendingJobMessage = (state, message) => {
    state.pendingJobMessage = message;
};

export const setInstituteId = (state, instituteId) => {
    state.instituteId = instituteId;
};

export const setZorgmailAccountId = (state, zorgmailAccountId) => {
    state.zorgmailAccountId = zorgmailAccountId;
};
