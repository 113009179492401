<template>
    <frm-panel :title="$t('calendar.sidebar.calendar')">
        <inline-date-picker ref="datepicker" @pick="handlePick" />

        <div class="calendar-datepicker">
            <div class="button-group">
                <button class="button button--small button--grouped" @click="previous()">
                    <span class="button__icon-group">
                        <frm-icon class="button__icon" name="caret-left" />
                    </span>
                </button>
            </div>

            <div class="button-group">
                <button class="button button--small button--secondary button--grouped" @click="today()">
                    <span class="button__icon-group">
                        {{ $t('calendar.today') }}
                    </span>
                </button>
            </div>

            <div class="button-group">
                <button class="button button--small button--grouped" @click="next()">
                    <span class="button__icon-group">
                        <frm-icon class="button__icon" name="caret-right" />
                    </span>
                </button>
            </div>
        </div>

        <div v-if="emptySchedulesMessage" class="calendar-datepicker__no-schedules" data-testid="calendar-sidebar:no-schedules">
            {{ emptySchedulesMessage }}
        </div>
    </frm-panel>
</template>

<script>
    import { createNamespacedHelpers } from 'vuex';
    import InlineDatePicker from './InlineDatePicker';
    import moment from 'moment';
    import Icon from '../Icon';

    const { mapActions, mapState, mapGetters } = createNamespacedHelpers('calendar');

    export default {
        components: {
            'frm-icon': Icon,
            'inline-date-picker': InlineDatePicker,
        },

        props: {
            selectionMode: {
                type: String,
                required: true,
            },
            date: {
                type: Object,
                required: true,
            },
        },

        watch: {
            selectionMode(value) {
                this.$refs.datepicker.selectionMode = value;
            },
            date(value) {
                this.$refs.datepicker.value = moment(value).toDate();
            },
        },

        mounted() {
            this.$refs.datepicker.selectionMode = this.selectionMode;
            this.$refs.datepicker.value = moment(this.date).toDate();
        },

        computed: {
            ...mapState(['filter']),
            ...mapGetters(['filterValueLabels', 'visibleSchedules']),
            emptySchedulesMessage() {
                if (this.visibleSchedules.length > 0 || !this.filter?.mode) {
                    return '';
                }

                if (this.filterValueLabels.length === 1) {
                    return this.$tc('calendar.sidebar.schedules-empty', 1, { filterValue: this.filterValueLabels[0] });
                }

                const filterMode = this.$tc(`calendar.filter-${this.filter.mode}`, this.filter.value.length).toLowerCase();

                return this.$tc('calendar.sidebar.schedules-empty', this.filter.value.length, { filterMode });
            },
        },

        methods: {
            ...mapActions([
                'next',
                'previous',
                'today',
            ]),
            handlePick(date) {
                this.$refs.datepicker.value = date;
                this.$emit('change', moment(date));
            },
        },
    };
</script>
