<script>
    import ModalManager from '../services/modalManager';

    export default {
        props: {
            url: {
                type: String,
                required: true,
            },
            size: {
                type: String,
                default: 'default',
            },
        },

        render() {
            return null;
        },

        async mounted() {
            const result = await ModalManager.openModal(this.url, undefined, undefined, this.size);

            this.$emit('closed', result);
        },
    };
</script>
