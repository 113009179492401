<script>
import Form from '../Form.vue';
import ModalManager from '../../services/modalManager';
import axios from 'axios';
import HandlesErrorResponses from '../../mixins/HandlesErrorResponses';
import HandlesJobs from '../../mixins/Overview/HandlesJobs';
import HandlesActions from '../../mixins/Overview/HandlesActions';

export default {
    mixins: [Form, HandlesErrorResponses, HandlesJobs, HandlesActions],

    methods: {
        async afterSubmit(event) {
            if (event) {
                event.preventDefault();
            }

            const params = new URLSearchParams(new FormData(this.$refs.form)).toString();
            const method = this.$refs.form.getAttribute('method');
            const url = `${this.$refs.form.getAttribute('action')}?${params}`;
            let job;

            try {
                job = (await axios(url, { method })).data;
            } catch (err) {
                await this.handleErrorResponse(err);
                return;
            }

            ModalManager.closeModal({ job });
        },
    },
};
</script>
