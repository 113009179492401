<script>
import axios from 'axios';
import Form from '../Form.vue';

export default {
    mixins: [Form],

    props: {
        downloadUrl: {
            type: String,
            required: true,
        },
        printUrl: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            action: '',
        };
    },

    methods: {
        download() {
            this.action = 'download';

            this.submit();
        },

        print() {
            this.action = 'print';

            this.submit();
        },

        async afterSubmit(event) {
            if (!this.action) {
                return;
            }

            if (event) {
                event.preventDefault();
            }

            const params = new URLSearchParams(new FormData(this.$refs.form));
            const method = params.has('_method') ? params.get('_method') : this.$refs.form.getAttribute('method');
            const url = `${this.$refs.form.getAttribute('action')}?${params.toString()}`;

            await axios({
                method,
                url,
            });

            this.formSubmitted = false;

            if (this.action === 'download') {
                this.action = '';
                window.location = this.downloadUrl;
            } else {
                this.action = '';
                window.open(this.printUrl);
            }
        },
    },
};
</script>
