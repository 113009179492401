<template>
    <div>
        <frm-spinner v-if="insurerGroupsLoading" />

        <div v-else class="overview__table">
            <template v-for="(insurerGroup, index) in insurerGroups">
                <input :key="'id_'+index"
                       type="hidden"
                       :name="'insurer_groups['+index+'][id]'"
                       :value="insurerGroup.id"
                >
                <input :key="'selected_'+index"
                       type="hidden"
                       :name="'insurer_groups['+index+'][selected]'"
                       :value="selectedInsurerGroupIds.includes(insurerGroup.id) ? 1 : 0"
                >
                <template v-if="selectedInsurerGroupIds.includes(insurerGroup.id)">
                    <input :key="'no_contract_'+index"
                           type="hidden"
                           :name="'insurer_groups['+index+'][no_contract]'"
                           :value="noContractInsurerGroupIds.includes(insurerGroup.id) ? 1 : 0"
                    >
                    <input :key="'contract_'+index"
                           type="hidden"
                           :name="'insurer_groups['+index+'][contract_id]'"
                           :value="contractIds[index]"
                    >
                    <input :key="'employee_'+index"
                           type="hidden"
                           :name="'insurer_groups['+index+'][employee_id]'"
                           :value="employeeIds[index]"
                    >
                </template>
            </template>

            <table class="table">
                <thead>
                    <tr>
                        <th />
                        <th>
                            <span class="table__th-wrapper">{{ $t('masterfiles.fields.insurer-group') }}</span>
                        </th>
                        <th>
                            <span class="table__th-wrapper">{{ $t('masterfiles.fields.contract') }}</span>
                        </th>
                        <th>
                            <span class="table__th-wrapper">{{ $t('masterfiles.fields.employee') }}</span>
                        </th>
                        <th>
                            <span class="table__th-wrapper" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(insurerGroup, index) in insurerGroups" :key="insurerGroup.id" class="zebra">
                        <td class="overview__column  overview__column--checkbox">
                            <div v-if="selectableInsurerGroupsIds.includes(insurerGroup.id)"
                                 class="form-checkbox overview-checkbox"
                            >
                                <input :id="'enabled-'+insurerGroup.id"
                                       v-model="selectedInsurerGroupIds"
                                       type="checkbox"
                                       :value="insurerGroup.id"
                                       class="form-radio__input form-checkbox__input"
                                >
                                <label class="form-checkbox__label" :for="'enabled-'+insurerGroup.id" />
                            </div>
                        </td>
                        <td>
                            <label :for="'enabled-'+insurerGroup.id">
                                {{ insurerGroup.description }}
                            </label>
                        </td>
                        <td>
                            <div v-if="contractEditableInsurerGroupIds.includes(insurerGroup.id)">
                                <frm-input-select
                                    v-if="contractEditableInsurerGroupIds.includes(insurerGroup.id)"
                                    :id="'contract-'+insurerGroup.id"
                                    v-model="contractIds[index]"
                                    :options="contractOptions[index]"
                                    :required="selectedInsurerGroupIds.includes(insurerGroup.id)"
                                    @input="select(insurerGroup.id)"
                                />
                            </div>
                            <template v-else-if="insurerGroup.no_contract && !editExistingContractInsurerGroupIds.includes(insurerGroup.id)">
                                <frm-icon class="information-list__icon" name="status-warning" />
                                {{
                                    $t('masterfiles.year-transition.no-contract', {
                                        'insurer-group': insurerGroup.description,
                                        'year': year
                                    })
                                }}
                            </template>
                            <template v-else-if="existingContractInsurerGroupIds.includes(insurerGroup.id) && !editExistingContractInsurerGroupIds.includes(insurerGroup.id)">
                                <frm-icon class="information-list__icon" name="status-achieved" />
                                {{
                                    $t('masterfiles.year-transition.contract-exists', {
                                        'insurer-group': insurerGroup.description,
                                        'contract': insurerGroup.last_contract_description,
                                        'year': year
                                    })
                                }}
                            </template>
                        </td>
                        <td>
                            <frm-input-select
                                v-if="employeeEditableInsurerGroupIds.includes(insurerGroup.id)"
                                :id="'employee-'+insurerGroup.id"
                                v-model="employeeIds[index]"
                                :options="employees"
                                :required="selectedInsurerGroupIds.includes(insurerGroup.id)"
                                @input="select(insurerGroup.id)"
                            />
                        </td>
                        <td class="overview__column">
                            <div class="form-checkbox">
                                <template v-if="insurerGroup.no_contract || existingContractInsurerGroupIds.includes(insurerGroup.id)">
                                    <input :id="'edit-contract-'+insurerGroup.id"
                                           v-model="editExistingContractInsurerGroupIds"
                                           type="checkbox"
                                           :value="insurerGroup.id"
                                           class="form-radio__input form-checkbox__input"
                                           @input="toggle(insurerGroup.id)"
                                    >
                                    <label class="form-checkbox__label" :for="'edit-contract-'+insurerGroup.id">
                                        {{ $t('masterfiles.year-transition.edit-contract') }}
                                    </label>
                                </template>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import HandlesInstitute from '../../mixins/HandlesInstitute';
import HandlesErrorResponses from '../../mixins/HandlesErrorResponses';

const NO_CONTRACT = 'NO_CONTRACT';

export default {
    mixins: [
        HandlesInstitute,
        HandlesErrorResponses,
    ],

    props: {
        year: {
            type: String,
            required: true,
        },
        therapyType: {
            type: String,
            required: true,
        },
        instituteChosenByClient: {
            type: String,
            required: false,
            default: '',
        },
        insurerGroupsRoute: {
            type: String,
            required: true,
        },
        employees: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            insurerGroupsLoading: true,
            submitButtonLoading: false,
            insurerGroups: [],
            contractIds: [],
            employeeIds: [],
            selectedInsurerGroupIds: [],
            editExistingContractInsurerGroupIds: [],
        };
    },

    watch: {
        year() {
            this.fetchContracts();
        },
        insurerGroupsFilters: {
            handler() {
                this.fetchContracts();
            },
            immediate: true,
        },
        insurerGroups() {
            const allInsurerGroupsNull = this.insurerGroups.map(() => null);

            this.contractIds = [...allInsurerGroupsNull];
            this.employeeIds = [...allInsurerGroupsNull];
        },
    },

    computed: {
        insurerGroupsFilters() {
            return {
                institute_id: this.instituteId,
                therapy_type: this.therapyType,
            };
        },
        insurerGroupsUrl() {
            return this.insurerGroupsRoute
                .replace(':year', this.year);
        },
        selectableInsurerGroupsIds() {
            return this.insurerGroups
                .filter(insurerGroup => {
                    if (insurerGroup.no_contract || this.existingContractInsurerGroupIds.includes(insurerGroup.id)) {
                        return this.editExistingContractInsurerGroupIds.includes(insurerGroup.id);
                    }

                    return true;
                })
                .map(insurerGroup => insurerGroup.id);
        },
        contractEditableInsurerGroupIds() {
            return this.insurerGroups
                .filter(insurerGroup => this.shouldShowContractEditFields(insurerGroup))
                .map(insurerGroup => insurerGroup.id);
        },
        employeeEditableInsurerGroupIds() {
            return this.insurerGroups
                .filter((insurerId, index) => this.contractIds[index] && this.contractIds[index] !== NO_CONTRACT)
                .map(insurerGroup => insurerGroup.id);
        },
        existingContractInsurerGroupIds() {
            return this.insurerGroups
                .filter(insurerGroup => String(insurerGroup.last_contract_year) === this.year)
                .filter(insurerGroup => !insurerGroup.no_contract)
                .map(insurerGroup => insurerGroup.id);
        },

        noContractInsurerGroupIds() {
            return this.insurerGroups
                .filter((insurerId, index) => this.contractIds[index] === NO_CONTRACT)
                .map(insurerGroup => insurerGroup.id);
        },

        contractOptions() {
            return this.insurerGroups.map(insurerGroup =>
                insurerGroup.no_contract ? insurerGroup.contracts : [
                    {
                        value: NO_CONTRACT,
                        label: this.$t('masterfiles.fields.no-contract'),
                    },
                    ...insurerGroup.contracts,
                ]);
        },
    },

    methods: {
        shouldShowContractEditFields(insurerGroup) {
            if (insurerGroup.no_contract && !this.editExistingContractInsurerGroupIds.includes(insurerGroup.id)) {
                return false;
            }

            if (this.existingContractInsurerGroupIds.includes(insurerGroup.id) && !this.editExistingContractInsurerGroupIds.includes(insurerGroup.id)) {
                return false;
            }

            return true;
        },
        async fetchContracts() {
            this.insurerGroupsLoading = true;

            if (this.$options.previousSource) {
                this.$options.previousSource.cancel();
                this.$options.previousSource = null;
            }

            this.$options.previousSource = axios.CancelToken.source();

            try {
                this.insurerGroups = (await axios.get(this.insurerGroupsUrl, {
                    cancelToken: this.$options.previousSource.token,
                    params: this.insurerGroupsFilters,
                })).data;
            } catch (thrown) {
                await this.handleErrorResponse(thrown);
            }
            this.insurerGroupsLoading = false;
        },
        select(insurerGroupId) {
            if (!this.selectedInsurerGroupIds.includes(insurerGroupId)) {
                this.selectedInsurerGroupIds.push(insurerGroupId);
            }
        },
        toggle(insurerGroupId) {
            if (!this.selectedInsurerGroupIds.includes(insurerGroupId)) {
                this.selectedInsurerGroupIds.push(insurerGroupId);
            } else {
                this.selectedInsurerGroupIds = this.selectedInsurerGroupIds.filter(id => id !== insurerGroupId);
            }
        },
    },
};
</script>
