<script>
import axios from 'axios';
import moment from 'moment';
import Form from '../Form.vue';
import AppointmentNote from '../Modals/AppointmentNote';
import ModalManager from '../../services/modalManager';
import dateFormatter from '../../i18n/dateFormatter';
import dateParser from '../../i18n/dateParser';
import AccessRights from '../../services/AccessRights';
import * as AccessRight from '../../enums/AccessRight';
import ProposalsModal from '../Calendar/Appointment/ProposalsModal.vue';
import CreatesHiddenInputs from '../../mixins/CreatesHiddenInputs';
import HandlesErrorResponses from '../../mixins/HandlesErrorResponses';
import { confirmIgnorableErrors } from '../../services/ignorableErrors';

const allDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
const allOrdinals = ['first', 'second', 'third', 'fourth', 'fifth'];
const newValuePlaceholder = '__NEW__';

export default {
    extends: Form,

    mixins: [
        CreatesHiddenInputs,
        HandlesErrorResponses,
    ],

    props: {
        isNew: {
            type: Boolean,
            default: false,
        },
        appointmentId: {
            type: String,
            default: null,
        },
        ignorableErrors: {
            type: Array,
            default: () => [],
        },
        performanceCodesRoute: {
            type: String,
            required: true,
        },
        locationInstituteMapping: {
            type: Object,
            required: true,
        },
        currentEmployee: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            activeTab: 'main',
            ignoreNextPatientIdWatch: false,
            fetchingEmailPreferences: false,
            fetchingIndicationHistories: false,
            fetchingSubscriptionItems: false,
            fetchingTemplates: false,
            fetchingPerformanceCodes: false,
            participations: [],
            availableIndicationHistories: {},
            availableSubscriptionItemData: {},
            templates: [],
            endWasProvided: false,
            addPatientId: 0,
            onlyActive: true,
            performanceCodes: [],
            // When editting an appointment, the next performance code should not be set automatically
            shouldSkipNextPerformanceCode: !this.isNew,
        };
    },

    computed: {
        confirmationTemplates() {
            return this.templates.filter(template => template.template_kind === 'standard');
        },
        confirmationTemplateOptions() {
            return this.confirmationTemplates.map(template => ({ label: template.description, value: template.id }));
        },
        defaultConfirmationTemplateId() {
            return this.confirmationTemplates
                .find(template => template.is_default_for_type)?.id || (this.confirmationTemplates.length ? this.confirmationTemplates[0].id : null);
        },
        reminderTemplates() {
            return this.templates.filter(template => template.template_kind === 'reminder');
        },
        reminderTemplateOptions() {
            return this.reminderTemplates.map(template => ({ label: template.description, value: template.id }));
        },
        defaultReminderTemplateId() {
            return this.reminderTemplates
                .find(template => template.is_default_for_type)?.id || (this.reminderTemplates.length ? this.reminderTemplates[0].id : null);
        },

        firstPatientAndIndicationHistory: {
            get() {
                const value = [];

                if (this.participations.length) {
                    value.push(this.participations[0].patient_id);
                    value.push(this.participations[0].indication_history_id);
                }

                return value;
            },
            set(value) {
                if (Array.isArray(value)) {
                    this.updateParticipation(value[0], {
                        indication_history_id: value[1],
                    }, true);
                }
            },
        },

        firstPatientId: {
            get() {
                // eslint-disable-next-line camelcase
                return this.participations[0]?.patient_id || null;
            },
            set(value) {
                this.updateParticipation(value, {}, true);
            },
        },

        firstPatientEmail() {
            return this.participations[0]?.email || null;
        },

        firstPatientLabel() {
            return this.participations[0]?.label || null;
        },

        firstSendReminder: {
            get() {
                // eslint-disable-next-line camelcase
                return this.participations[0]?.send_reminder || false;
            },
            set(value) {
                this.setSendReminder(this.firstPatientId, value);
            },
        },

        firstSendConfirmation: {
            get() {
                // eslint-disable-next-line camelcase
                return this.participations[0]?.send_confirmation || false;
            },
            set(value) {
                this.setSendConfirmation(this.firstPatientId, value);
            },
        },

        firstConfirmationTemplateId: {
            get() {
                // eslint-disable-next-line camelcase
                return this.participations[0]?.confirmation_template_id || null;
            },
            set(value) {
                if (this.firstConfirmationTemplateId !== value) {
                    this.setConfirmationTemplateId(this.firstPatientId, value);
                }
            },
        },

        firstReminderTemplateId: {
            get() {
                // eslint-disable-next-line camelcase
                return this.participations[0]?.reminder_template_id || null;
            },
            set(value) {
                if (this.firstReminderTemplateId !== value) {
                    this.setReminderTemplateId(this.firstPatientId, value);
                }
            },
        },

        patientIds: {
            get() {
                return this.participations.map(participation => participation.patient_id);
            },
            set(value) {
                // changing the patientIds from another tab raises issues with reactivity from the ServerSelect.
                if (this.activeTab !== 'main') {
                    return;
                }

                const newPatientIds = [];
                this.participations = value.map(patientId => {
                    const idx = this.getParticipationIdx(patientId);

                    if (idx === -1) {
                        const participation = this.createEmptyParticipationEntry(patientId);
                        newPatientIds.push(patientId);

                        return participation;
                    }

                    return this.participations[idx];
                });

                newPatientIds.forEach(patientId => this.fetchParticipationData(patientId));
            },
        },

        firstIndicationId: {
            get() {
                // eslint-disable-next-line camelcase
                return this.participations[0]?.indication_history_id || null;
            },
            set(value) {
                if (value && this.firstIndicationId !== value) {
                    this.updateParticipation(this.firstPatientId, { indication_history_id: value });
                }
            },
        },

        firstSubscriptionItemDataId: {
            get() {
                // eslint-disable-next-line camelcase
                return this.participations[0]?.subscription_item_data_id || null;
            },
            set(value) {
                if (this.firstSubscriptionItemDataId !== value) {
                    this.selectSubscriptionItemData(this.firstPatientId, value);
                }
            },
        },

        durationInMinutes: {
            get() {
                if (!this.formData.start || !this.formData.end) {
                    return null;
                }

                const start = dateParser(this.formData.start);
                const end = dateParser(this.formData.end);

                return Math.round((end.getTime() - start.getTime()) / 60000);
            },
            set(value) {
                if (!this.formData.start || !value) {
                    return;
                }

                const start = dateParser(this.formData.start);
                const end = new Date(start.getTime() + value * 60000);

                this.formData.end = dateFormatter(end);
            },
        },
        patientOptions() {
            return this.participations.map(participation => ({
                label: participation.label,
                value: participation.patient_id,
            }));
        },
        patientOptionsString() {
            return JSON.stringify(this.patientOptions);
        },
        isPhysicaltherapy() {
            return this.formData.appointment_type === 'physicaltherapy';
        },
        isService() {
            return this.formData.appointment_type === 'service';
        },
        isSubscription() {
            return this.formData.appointment_type === 'subscription';
        },
        isWithoutPatient() {
            return this.formData.appointment_type === 'without_patient';
        },
        hasMultiplePatients() {
            return !!this.formData.multiple_patients;
        },
        usesOnlineIntake() {
            return this.formData.appointment_type === 'physicaltherapy' && AccessRights.hasAccessTo(AccessRight.FYSIOROADMAP_ONLINE_QUESTIONNAIRES);
        },
        onlineIntakeButtonDisabled() {
            return this.hasMultiplePatients || this.onlineIntakeWasSent !== false || !this.firstPatientId || !this.firstIndicationId || this.isRecurring;
        },
        isRecurring() {
            return Boolean(this.formData.is_recurring);
        },
        sequencePattern() {
            return this.formData.sequence_pattern;
        },
        proposalsRequestData() {
            const devices = this.formData.location_devices.filter(val => !!val);
            const rooms = this.formData.location_rooms.filter(val => !!val);

            const proposalsRequestData = {
                location: this.formData.location,
                employees: this.formData.employees,
                sequence_pattern: this.sequencePattern,
                location_device: devices.length ? devices[0] : null,
                location_room: rooms.length ? rooms[0] : null,
                pattern_starts_on_date: this.formData.start,
                start_time_appointment: this.formData.start,
                end_time_appointment: this.formData.end,
                patient_count: this.patientIds.length,
            };

            if (this.sequencePattern === 'days') {
                proposalsRequestData.sequence_occurs_every = this.formData.sequence_occurs_every;
                proposalsRequestData.day_selection = this.formData.day_selection;
            }

            if (this.sequencePattern === 'weeks') {
                proposalsRequestData.sequence_occurs_every = this.formData.sequence_occurs_every;
                proposalsRequestData.sequence_occurs_on = this.formData.weeks_sequence_occurs_on;
            }

            if (this.sequencePattern === 'months') {
                proposalsRequestData.sequence_occurs_every = this.formData.sequence_occurs_every;

                if (this.formData.month_sequence_type === 'day_number') {
                    proposalsRequestData.sequence_occurs_on_day_number = this.formData.sequence_occurs_on_day_number;
                }

                if (this.formData.month_sequence_type === 'ordinal') {
                    proposalsRequestData.sequence_occurs_on_ordinal = this.formData.sequence_occurs_on_ordinal;
                    proposalsRequestData.sequence_occurs_on = this.formData.sequence_occurs_on;
                }
            }

            if (this.sequencePattern === 'years') {
                proposalsRequestData.sequence_occurs_on_ordinal = this.formData.sequence_occurs_on_ordinal;
                proposalsRequestData.sequence_occurs_on = this.formData.sequence_occurs_on;
                proposalsRequestData.monthnumber = this.formData.monthnumber;
            }

            if (this.formData.recurring_end_type === 'times') {
                proposalsRequestData.sequence_count = this.formData.sequence_count;
            }

            if (this.formData.recurring_end_type === 'end_date') {
                proposalsRequestData.pattern_ends_on_date = this.formData.pattern_ends_on_date;
            }

            return proposalsRequestData;
        },
        firstPatientIndicationHistories() {
            if (!this.participations.length || !Object.hasOwn(this.availableIndicationHistories, this.participations[0].patient_id)) {
                return [];
            }

            return this.availableIndicationHistories[this.participations[0].patient_id];
        },
        firstPatientSubscriptionsItems() {
            if (!this.participations.length || !Object.hasOwn(this.availableSubscriptionItemData, this.participations[0].patient_id)) {
                return [];
            }

            return this.availableSubscriptionItemData[this.participations[0].patient_id];
        },
        onlineIntakeWasSent() {
            return this.participations.some(participation => participation.has_sent_online_intake);
        },
        performanceCodeIsCombination() {
            return this.formData.performance_code?.includes('combination-') || false;
        },
        nextPerformanceCode() {
            return this.performanceCodes.find(performanceCode => !!performanceCode.is_next)?.value || null;
        },
        performanceCodeAppointmentLength() {
            return this.performanceCodes
                // eslint-disable-next-line camelcase
                .find(performanceCode => performanceCode.value === this.formData.performance_code)?.appointment_length || null;
        },
        startDate() {
            return this.formData.start ? dateFormatter(dateParser(this.formData.start), 'YYYY-MM-DD') : null;
        },
        fetchPerformanceCodeParams() {
            return {
                valid_on_date: this.startDate,
                indication_history_id: this.firstIndicationId,
            };
        },
        fetchPerformanceCodeParamsString() {
            return JSON.stringify(this.fetchPerformanceCodeParams);
        },
    },

    watch: {
        'formData.appointment_type'() {
            if (!this.$refs.cascader) {
                return;
            }

            const option = this.$refs.cascader.getSelectedOption();

            if (!option) {
                this.$nextTick(() => this.$refs.cascader.setQueryByValue());
                return;
            }

            // remove the children (indications) when switching appointment types

            this.$refs.cascader.setOptionsFromServer([{
                label: option.label,
                value: option.value,
            }]);

            this.$nextTick(() => this.$refs.cascader.setQueryByValue());
        },

        nextPerformanceCode() {
            if (!this.nextPerformanceCode || this.shouldSkipNextPerformanceCode) {
                this.shouldSkipNextPerformanceCode = false;

                return;
            }

            this.formData.performance_code = this.nextPerformanceCode;
        },

        performanceCodeAppointmentLength() {
            if (!this.isNew || !this.performanceCodeAppointmentLength || this.endWasProvided) {
                return;
            }

            this.durationInMinutes = this.performanceCodeAppointmentLength;
        },

        isWithoutPatient() {
            if (this.isWithoutPatient) {
                this.participations = [];
                this.$refs.tabs.select('main');
                this.formData.multiple_patients = false;
            }
        },

        'formData.start'(newValue, oldValue) {
            if (oldValue && newValue) {
                const oldStart = dateParser(oldValue);
                const newStart = dateParser(newValue);

                const delta = newStart.getTime() - oldStart.getTime();

                if (this.formData.end) {
                    const oldEnd = dateParser(this.formData.end);
                    const newEnd = new Date(oldEnd.getTime() + delta);

                    this.formData.end = dateFormatter(newEnd);
                }
            }

            this.prefillRecurringForm();
        },

        startDate() {
            this.participations.forEach(participation => this.fetchSubscriptionItems(participation.patient_id));
        },

        'formData.note'(note) {
            if (this.isNew) {
                return;
            }

            return axios.patch(`/calendar/appointment/${this.appointmentId}`, { note });
        },

        addPatientId(patientId) {
            if (patientId) {
                this.updateParticipation(patientId);

                this.addPatientId = '';
            }
        },

        isRecurring() {
            this.$nextTick(() => {
                ModalManager.recalculateModalHeight();
            });
        },

        sequencePattern() {
            this.$nextTick(() => {
                ModalManager.recalculateModalHeight();
            });
        },

        participations() {
            this.setParticipationsFormData();

            this.$nextTick(() => {
                ModalManager.recalculateModalHeight();
            });
        },

        hasMultiplePatients() {
            if (!this.hasMultiplePatients) {
                this.participations = this.participations.slice(0, 1);
            }

            this.$nextTick(() => {
                ModalManager.recalculateModalHeight();
            });
        },

        performanceCodeIsCombination() {
            if (this.performanceCodeIsCombination) {
                this.formData.multiple_patients = false;
                this.formData.is_recurring = false;
            }

            this.$nextTick(() => {
                ModalManager.recalculateModalHeight();
            });
        },

        fetchPerformanceCodeParamsString() {
            this.fetchPerformanceCodes();
        },

        firstPatientLabel(label) {
            if (this.$refs.cascader && label) {
                this.$refs.cascader.setOptionsFromServer([{ value: this.firstPatientId, label }]);
                this.$nextTick(() => this.$refs.cascader.setQueryByValue());
            }
        },

        'formData.location'(location, oldLocation) {
            const oldInstituteId = this.locationInstituteMapping[oldLocation];
            const newInstituteId = this.locationInstituteMapping[location];

            if (oldInstituteId !== newInstituteId) {
                this.fetchTemplates();
            }
        },
    },

    created() {
        if (this.formData.calendar_item_participations) {
            this.participations = this.formData.calendar_item_participations;
        }

        this.endWasProvided = !!this.formData.end;
        this.prefillRecurringForm();
        this.fetchTemplates();
        this.participations.forEach(participation => {
            this.fetchIndicationHistories(participation.patient_id);
            this.fetchSubscriptionItems(participation.patient_id);
        });

        if (this.isNew) {
            this.fetchPerformanceCodes();
        }
    },

    async mounted() {
        if (!this.ignorableErrors.length) {
            return;
        }

        const force = await confirmIgnorableErrors(this.ignorableErrors);

        if (force) {
            this.formData.force = true;

            // Prevent the unload dialog from showing
            this.formSubmitted = true;

            this.$nextTick(() => this.$refs.form.submit());
        }
    },

    methods: {
        setParticipationsFormData() {
            [...this.$refs.form.childNodes]
                .filter(node => node.tagName?.toLowerCase() === 'input')
                .filter(node => node.name?.startsWith('calendar_item_participations'))
                .forEach(node => this.$refs.form.removeChild(node));

            this.participations
                .forEach((participation, idx) => {
                    this.$refs.form.appendChild(this.createHiddenInput(`calendar_item_participations[${idx}][id]`, participation.id));
                    this.$refs.form.appendChild(this.createHiddenInput(`calendar_item_participations[${idx}][patient_id]`, participation.patient_id));
                    this.$refs.form.appendChild(this.createHiddenInput(`calendar_item_participations[${idx}][indication_history_id]`, participation.indication_history_id));
                    this.$refs.form.appendChild(this.createHiddenInput(`calendar_item_participations[${idx}][subscription_item_data_id]`, participation.subscription_item_data_id));
                    this.$refs.form.appendChild(this.createHiddenInput(`calendar_item_participations[${idx}][send_confirmation]`, participation.send_confirmation));
                    this.$refs.form.appendChild(this.createHiddenInput(`calendar_item_participations[${idx}][send_reminder]`, participation.send_reminder));
                    this.$refs.form.appendChild(this.createHiddenInput(`calendar_item_participations[${idx}][confirmation_template_id]`, participation.confirmation_template_id));
                    this.$refs.form.appendChild(this.createHiddenInput(`calendar_item_participations[${idx}][reminder_template_id]`, participation.reminder_template_id));
                    this.$refs.form.appendChild(this.createHiddenInput(`calendar_item_participations[${idx}][label]`, participation.label));
                });
        },
        async afterSubmit(event) {
            if (!this.isRecurring) {
                return;
            }

            this.formSubmitted = false;

            event.preventDefault();

            let proposals;
            try {
                proposals = (await axios.post('/calendar/appointment/proposals', this.proposalsRequestData)).data.data;
            } catch (err) {
                await this.handleErrorResponse(err);
                return;
            }

            await ModalManager.openModal(ProposalsModal, {
                proposals,
            }, {
                save: enabledProposals => {
                    enabledProposals.forEach((proposal, idx) =>
                        Object.entries(proposal).forEach(([name, value]) => this.$refs.form.appendChild(this.createHiddenInput(`appointment_proposals[${idx}][${name}]`, value))));

                    this.formSubmitted = true;

                    this.$nextTick(() => this.$refs.form.submit());
                },
            });
        },

        async fetchIndicationHistories(patientId) {
            this.fetchingIndicationHistories = true;

            const currentIndicationHistoryId = this.getParticipationIdx(patientId) === -1
                ? null
                : this.participations[this.getParticipationIdx(patientId)].indication_history_id;

            try {
                const data = (await (axios.get('/patient/indication_histories', {
                    params: {
                        patient_id: patientId,
                        include: currentIndicationHistoryId,
                    },
                }))).data;

                this.$set(this.availableIndicationHistories, patientId, data);
                this.updateOnlineIntakeWasSent(patientId);
            } finally {
                this.fetchingIndicationHistories = false;
            }
        },

        async fetchSubscriptionItems(patientId) {
            this.fetchingSubscriptionItems = true;

            try {
                const items = (await (axios.get('/patient/subscription_item_data', {
                    params: {
                        patient_id: patientId,
                        date: this.startDate,
                    },
                }))).data;

                this.$set(this.availableSubscriptionItemData, patientId,
                    [...items, {
                        label: this.$t('calendar.appointment.new-subscription'),
                        value: newValuePlaceholder,
                    }]);

                if (items.length === 1 && !this.firstSubscriptionItemDataId) {
                    this.updateParticipation(patientId, { subscription_item_data_id: items[0].value });
                }
            } finally {
                this.fetchingSubscriptionItems = false;
            }
        },

        async fetchTemplates() {
            if (!this.formData.location) {
                return;
            }

            this.fetchingTemplates = true;

            try {
                this.templates = (await axios.get('/calendar/appointment/templates', {
                    params: {
                        institute_id: this.locationInstituteMapping[this.formData.location],
                    },
                })).data;

                this.participations.forEach(participation => {
                    const confirmationTemplateIds = this.confirmationTemplates.map(template => template.id);
                    const reminderTemplateIds = this.reminderTemplates.map(template => template.id);

                    if (participation.send_confirmation && !confirmationTemplateIds.includes(participation.confirmation_template_id)) {
                        this.setConfirmationTemplateId(participation.patient_id, this.defaultConfirmationTemplateId);
                    }

                    if (participation.send_reminder && !reminderTemplateIds.includes(participation.reminder_template_id)) {
                        this.setReminderTemplateId(participation.patient_id, this.defaultReminderTemplateId);
                    }
                });
            } finally {
                this.fetchingTemplates = false;
            }
        },

        updateOnlineIntakeWasSent(patientId) {
            if (!Object.hasOwn(this.availableIndicationHistories, patientId)) {
                return;
            }

            const participation = this.getParticipation(patientId);

            // eslint-disable-next-line camelcase
            if (!participation?.indication_history_id) {
                return;
            }

            const indicationHistory = this.availableIndicationHistories[patientId].find(item => item.value === participation.indication_history_id);

            this.updateParticipation(patientId, {
                // eslint-disable-next-line camelcase
                has_sent_online_intake: Boolean(indicationHistory?.extra_data?.has_sent_online_intake),
            });
        },

        selectIndicationHistoryId(patientId, indicationHistoryId) {
            this.updateParticipation(patientId, { indication_history_id: indicationHistoryId });
        },

        selectSubscriptionItemData(patientId, subscriptionItemDataId) {
            if (subscriptionItemDataId === newValuePlaceholder) {
                this.showCreateSubscriptionItemDataModal(patientId);

                return;
            }

            this.updateParticipation(patientId, { subscription_item_data_id: subscriptionItemDataId });
        },

        setSendConfirmation(patientId, sendConfirmation) {
            this.updateParticipation(patientId, {
                send_confirmation: !!sendConfirmation,
                confirmation_template_id: sendConfirmation
                    ? this.defaultConfirmationTemplateId
                    : null,
            });
        },

        setSendReminder(patientId, sendReminder) {
            this.updateParticipation(patientId, {
                send_reminder: !!sendReminder,
                reminder_template_id: sendReminder
                    ? this.defaultReminderTemplateId
                    : null,
            });
        },

        setConfirmationTemplateId(patientId, confirmationTemplateId) {
            this.updateParticipation(patientId, { confirmation_template_id: confirmationTemplateId });
        },

        setReminderTemplateId(patientId, reminderTemplateId) {
            this.updateParticipation(patientId, { reminder_template_id: reminderTemplateId });
        },

        prefillRecurringForm() {
            if (!this.formData.start) {
                return;
            }

            const start = moment(this.formData.start);
            const weekday = allDays[start.isoWeekday() - 1];
            const ordinal = allOrdinals[Math.floor(start.date() / 7)];

            this.formData.pattern_ends_on_date = dateFormatter(start.clone().add(1, 'year'));
            this.formData.monthnumber = start.month() + 1;
            this.formData.sequence_occurs_on = weekday;
            this.formData.sequence_occurs_on_day_number = start.date();
            this.formData.sequence_occurs_on_ordinal = ordinal;
            this.formData.weeks_sequence_occurs_on = [weekday];
        },

        openNoteModal() {
            ModalManager.openModal(
                AppointmentNote,
                {
                    note: this.formData.note,
                    currentEmployee: this.currentEmployee,
                },
                {
                    'set-note': ({ note }) => {
                        this.formData.note = note;
                    },
                },
                'medium'
            );
        },

        async fetchPatientEmailPreferences(patientId) {
            this.fetchingEmailPreferences = true;

            try {
                const preferences = (await axios.get('/calendar/appointment/email-preferences', { params: { patient_id: patientId } })).data;

                this.updateParticipation(patientId, {
                    send_confirmation: !!preferences.send_confirmation,
                    send_reminder: !!preferences.send_reminder,
                    confirmation_template_id: preferences.send_confirmation
                        ? this.defaultConfirmationTemplateId
                        : null,
                    reminder_template_id: preferences.send_reminder
                        ? this.defaultReminderTemplateId
                        : null,
                });
            } finally {
                this.fetchingEmailPreferences = false;
            }
        },

        async fetchPatientData(patientId) {
            const data = (await axios.get('/calendar/appointment/patient-data', { params: { patient_id: patientId } })).data;
            this.updateParticipation(patientId, { label: data.label, email: data.email });
        },

        deleteParticipation(patientId) {
            this.participations = this.participations.filter(participation => participation.patient_id !== patientId);
        },

        async fetchPerformanceCodes() {
            this.fetchingPerformanceCodes = true;
            try {
                this.performanceCodes = (await axios.get(this.performanceCodesRoute, {
                    params: {
                        ...this.fetchPerformanceCodeParams,
                        include: this.formData.performance_code,
                    },
                })).data.data;
            } finally {
                this.fetchingPerformanceCodes = false;
            }
        },

        async showCreatePatientModal(query = '') {
            const data = await ModalManager.openModal(`/patient/create?query=${query}`, undefined, undefined, 'full');

            if (!data) {
                return;
            }

            if (this.$refs.cascader) {
                this.$refs.cascader.setOptionsFromServer([{ value: data.id, label: data.name.fullname }]);
                this.$nextTick(() => {
                    this.$refs.cascader.setQueryByValue();
                });
            }

            const participation = {
                patient_id: data.id,
                label: data.name.fullname,
                email: data.address.email,
                send_reminder: Boolean(data.address.email),
                send_confirmation: Boolean(data.address.email),
            };

            if (this.hasMultiplePatients) {
                this.participations.push(participation);
            } else {
                this.participations = [participation];
            }

            await this.fetchParticipationData(data.id);

            // Select the first indication history if there is one for the newly created patient
            if (this.availableIndicationHistories[data.id].length) {
                this.updateParticipation(data.id, { indication_history_id: this.availableIndicationHistories[data.id][0].value });
            }
        },

        async showCreateSubscriptionItemDataModal(patientId) {
            const data = await ModalManager.openModal(`/patient/${patientId}/subscriptions/create`);

            if (!data) {
                this.updateParticipation(patientId, { subscription_item_data_id: null });

                return;
            }

            await this.fetchSubscriptionItems(patientId);

            this.updateParticipation(patientId, { subscription_item_data_id: data.id });
        },

        updateParticipation(patientId, props = {}, forceSingle = false) {
            const idx = this.getParticipationIdx(patientId);

            const data = {
                ...idx === -1 ? this.createEmptyParticipationEntry(patientId) : this.participations[idx],
                ...props,
            };

            if (forceSingle) {
                this.participations = [data];
            } else if (idx === -1) {
                this.participations.push(data);
            } else {
                this.$set(this.participations, idx, data);
            }

            if (idx === -1 || forceSingle) {
                this.fetchParticipationData(patientId);
            }
        },

        async fetchParticipationData(patientId) {
            const promises = [];
            if (!Object.hasOwn(this.availableIndicationHistories, patientId)) {
                promises.push(this.fetchIndicationHistories(patientId));
            }

            if (!Object.hasOwn(this.availableSubscriptionItemData, patientId)) {
                promises.push(this.fetchSubscriptionItems(patientId));
            }

            const participation = this.getParticipation(patientId);

            if (participation.send_reminder === null && participation.send_confirmation === null) {
                promises.push(this.fetchPatientEmailPreferences(patientId));
            }

            if (participation.label === null || participation.email === null) {
                promises.push(this.fetchPatientData(patientId));
            }

            return Promise.all(promises);
        },

        createEmptyParticipationEntry(patientId) {
            return {
                id: '',
                patient_id: String(patientId),
                label: null,
                indication_history_id: null,
                subscription_item_data_id: null,
                send_confirmation: null,
                send_reminder: null,
                confirmation_template_id: null,
                reminder_template_id: null,
                has_sent_online_intake: null,
            };
        },

        getParticipation(patientId) {
            return this.participations[this.getParticipationIdx(patientId)];
        },

        getParticipationIdx(patientId) {
            return this.participations.findIndex(participation => participation.patient_id === String(patientId));
        },
    },
};
</script>
