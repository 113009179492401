<template>
    <div class="appointment-info">
        <template v-if="!anonymous">
            <frm-panel v-if="loading || !appointment" :title="event.description" toggleable>
                <div class="info-list">
                    <div v-if="loading" class="info-list__loader">
                        <frm-spinner />
                    </div>
                    <div v-else v-text="$t('calendar.sidebar.error')" />
                </div>
            </frm-panel>

            <template v-else>
                <frm-panel key="note-panel" :title="$t('calendar.note')" toggleable :initially-opened="!!event.has_note" class="appointment-info__panel">
                    <template v-if="noteHasChanged" #header-extra>
                        <button type="button" class="panel__header-button" :title="$t('masterfiles.actions.save')" @click.stop="saveNote">
                            <frm-icon name="check" class="panel__header-button-icon" />
                        </button>
                        <button type="button" class="panel__header-button" :title="$t('masterfiles.actions.cancel')" @click.stop="resetNote">
                            <frm-icon name="cross" class="panel__header-button-icon" />
                        </button>
                    </template>
                    <textarea-autosize v-model="note" class="form-textarea form-textarea--required appointment-info__note" :min-height="100" />
                </frm-panel>

                <frm-panel v-if="isWithoutPatient" :title="event.description" toggleable>
                    <div class="info-list">
                        <dl class="info-list__overview">
                            <dt class="info-list__label" v-text="$t('calendar.appointment.appointment_subject')" />
                            <dd class="info-list__value" v-text="appointment.appointment_subject ? appointment.appointment_subject.description : null" />
                            <dt class="info-list__label" v-text="$t('calendar.appointment.title')" />
                            <dd class="info-list__value" v-text="appointment.title" />
                        </dl>
                    </div>
                </frm-panel>
                <template v-else>
                    <frm-panel
                        v-for="item in participationsEnhanced"
                        :key="item.appointmentParticipation.id"
                        :initially-opened="participationsOpenedInitially"
                        :title="`${item.appointmentParticipation.patient.name.fullname} (${$t(`patient.values.gender-${item.appointmentParticipation.patient.gender}`)})`"
                        toggleable
                        @opened="fetchPaymentStatus(false)"
                    >
                        <template #header-extra>
                            <button type="button" :data-participation="item.appointmentParticipation.id" class="panel__header-button js-participation-actions" @click.stop="triggerParticipationActionContextmenu">
                                <frm-icon name="dots" class="panel__header-button-icon" />
                            </button>
                        </template>

                        <div v-if="!paymentStatusFetched || paymentStatusLoading" class="info-list">
                            <div v-if="paymentStatusLoading" class="info-list__loader">
                                <frm-spinner />
                            </div>
                            <div v-else v-text="$t('calendar.sidebar.error')" />
                        </div>
                        <template v-else-if="item.paymentParticipation">
                            <frm-patient-card
                                :name="item.paymentParticipation.patient_name"
                                :birthdate="item.paymentParticipation.date_of_birth"
                                :gender="item.paymentParticipation.gender"
                                :identification-number="item.paymentParticipation.identification_number"
                                :photo="item.paymentParticipation.photo_id"
                            />

                            <div class="info-list">
                                <dl class="info-list__overview">
                                    <dt v-if="item.paymentParticipation.patient_address_phone_private" class="info-list__label" v-text="$t('patient.dashboard.terms.phone')" />
                                    <dd v-if="item.paymentParticipation.patient_address_phone_private" class="info-list__value">
                                        <a :href="'tel:'+item.paymentParticipation.patient_address_phone_private"
                                           v-text="item.paymentParticipation.patient_address_phone_private"
                                        />
                                    </dd>
                                    <dt v-if="item.paymentParticipation.patient_address_phone_mobile" class="info-list__label" v-text="$t('patient.dashboard.terms.phone_mobile')" />
                                    <dd v-if="item.paymentParticipation.patient_address_phone_mobile" class="info-list__value">
                                        <a :href="'tel:'+item.paymentParticipation.patient_address_phone_mobile"
                                           v-text="item.paymentParticipation.patient_address_phone_mobile"
                                        />
                                    </dd>
                                    <dt v-if="item.address.length" class="info-list__label" v-text="$t('masterfiles.fields.address')" />
                                    <dd v-if="item.address.length" class="info-list__value">
                                        <a :href="'https://maps.google.com/?q='+encodeURIComponent(item.address.join(', '))"
                                           target="_blank"
                                           v-html="item.address.join('<br>')"
                                        />
                                    </dd>
                                </dl>
                                <div v-if="item.paymentParticipation.patient_address_phone_private || item.paymentParticipation.patient_address_phone_mobile || item.address.length" class="info-list-separator" />
                            </div>
                            <frm-calendar-appointment-info-details-treatment
                                v-if="isTreatment"
                                :participation="item.paymentParticipation"
                                :diagnosis-code-enabled="diagnosisCodeEnabled"
                            />

                            <frm-calendar-appointment-info-details-service
                                v-if="isService"
                                :participation="item.paymentParticipation"
                                :service="appointment.service"
                            />

                            <frm-calendar-appointment-info-details-subscription
                                v-if="isSubscription"
                                :participation="item.paymentParticipation"
                            />

                            <div v-if="isTreatment && !item.paymentParticipation.patient_has_additional_insurance" class="calendar-legend">
                                <div class="appointment-info">
                                    <h3 class="info-list__header">
                                        {{ $t('patient.dashboard.terms.no-additional-insurance') }}
                                    </h3>
                                </div>
                                <div class="info-list-separator" />
                            </div>

                            <ul class="calendar-legend">
                                <li class="calendar-legend__item calendar-legend__item--bullet" :style="{'--item-color': item.paymentParticipation.status_color}" v-text="item.paymentParticipation.status" />
                            </ul>

                            <div v-if="paymentStatusCachedAt" class="appointment-info__outdated-warning">
                                <p>
                                    {{ $t('calendar.sidebar.appointment-cached-at', {date: paymentStatusCachedAtFormatted}) }}
                                </p>

                                <button class="button button--small button--grouped" @click="refreshPaymentStatus(false)">
                                    <span class="button__icon-group">
                                        <frm-icon class="button__icon" name="refresh-arrows" />
                                    </span>
                                </button>
                            </div>
                        </template>
                    </frm-panel>
                </template>
            </template>
        </template>

        <frm-panel :title="$t('calendar.sidebar.appointment')" toggleable>
            <div class="info-list">
                <dl class="info-list__overview">
                    <dt class="info-list__label" v-text="$t('calendar.appointment.date')" />
                    <dd class="info-list__value" v-text="date" />
                    <dt class="info-list__label" v-text="$t('calendar.appointment.time')" />
                    <dd class="info-list__value" v-text="time" />
                    <dt class="info-list__label" v-text="$t('calendar.appointment.employee')" />
                    <dd class="info-list__value" v-html="employees" />
                    <dt class="info-list__label" v-text="$t('calendar.appointment.location')" />
                    <dd class="info-list__value" v-text="location" />
                    <template v-if="rooms">
                        <dt class="info-list__label" v-text="$t('calendar.appointment.room')" />
                        <dd class="info-list__value" v-html="rooms" />
                    </template>
                    <template v-if="devices">
                        <dt class="info-list__label" v-text="$t('calendar.appointment.device')" />
                        <dd class="info-list__value" v-html="devices" />
                    </template>
                </dl>
            </div>
        </frm-panel>
    </div>
</template>

<script>
import S from 'string';
import $ from 'jquery';
import 'jquery-contextmenu';
import 'jquery-ui/ui/position';
import jsonApi from '../../json-api-client';
import AccessRights from '../../services/AccessRights';
import * as AccessRight from '../../enums/AccessRight';
import ModalManager from '../../services/modalManager';
import buildContextMenuItemsFromActions from '../../services/buildContextMenuItemsFromActions';
import * as CalendarItemOption from '../../enums/CalendarItemOption';
import * as ReimbursementUnit from '../../enums/ReimbursementUnit';
import MasterFinancialStatusColors from '../../enums/MasterFinancialStatusColors';
import DialogManager from '../../services/DialogManager';
import doCovCheck from '../../services/covCheck';
import Treatment from './AppointmentInfoDetails/Treatment.vue';
import Service from './AppointmentInfoDetails/Service.vue';
import CalendarItemWithService from '../../definitions/CalendarItemWithService';
import CalendarItemWithPerformanceCode from '../../definitions/CalendarItemWithPerformanceCode';
import invoiceAndPrint from '../../services/invoiceAndPrint';
import CalendarItemWithSubscription from '../../definitions/CalendarItemWithSubscription';
import Subscription from './AppointmentInfoDetails/Subscription.vue';
import CalendarItemWithoutPatient from '../../definitions/CalendarItemWithoutPatient';
import axios from 'axios';
import HandlesActions from '../../mixins/Overview/HandlesActions';
import HandlesJobs from '../../mixins/Overview/HandlesJobs';
import HandlesErrorResponses from '../../mixins/HandlesErrorResponses';

export default {
    components: {
        'frm-calendar-appointment-info-details-service': Service,
        'frm-calendar-appointment-info-details-subscription': Subscription,
        'frm-calendar-appointment-info-details-treatment': Treatment,
    },
    mixins: [HandlesErrorResponses, HandlesActions, HandlesJobs],
    props: {
        event: {
            type: Object,
            required: true,
        },

        anonymous: {
            type: Boolean,
            default: false,
        },

        diagnosisCodeEnabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            loading: false,
            paymentStatusLoading: false,
            paymentStatusFetched: false,
            paymentStatusCachedAt: null,
            appointment: null,
            paymentStatusses: [],
            note: '',
        };
    },

    computed: {
        date() {
            return this.$ds(this.event.start, 'date');
        },

        devices() {
            return this.event.location_devices
                .filter(device => !!device)
                .map(device => S(device.description).escapeHTML().toString()).join('<br>');
        },

        employees() {
            return this.event.employees
                .filter(employee => !!employee)
                .map(employee => S(employee.name.fullname).escapeHTML().toString()).join('<br>');
        },

        location() {
            return this.event.location?.description;
        },

        rooms() {
            return this.event.location_rooms
                .filter(room => !!room)
                .map(room => S(room.description).escapeHTML().toString()).join('<br>');
        },

        time() {
            return `${this.$ds(this.event.start, 'time')} - ${this.$ds(this.event.end, 'time')}`;
        },

        isService() {
            return this.appointment.type === CalendarItemWithService.name;
        },

        isSubscription() {
            return this.appointment.type === CalendarItemWithSubscription.name;
        },

        isTreatment() {
            return this.appointment.type === CalendarItemWithPerformanceCode.name;
        },

        isWithoutPatient() {
            return this.appointment.type === CalendarItemWithoutPatient.name;
        },

        participationsOpenedInitially() {
            return this.paymentStatusFetched && this.paymentStatusses.length === 1;
        },

        paymentStatusCachedAtFormatted() {
            if (!this.paymentStatusCachedAt) {
                return null;
            }

            // if today, only show time
            if (this.$ds(this.paymentStatusCachedAt, 'date') === this.$ds(new Date().toISOString(), 'date')) {
                return this.$ds(this.paymentStatusCachedAt, 'time');
            }

            return this.$ds(this.paymentStatusCachedAt, 'date');
        },

        participationsEnhanced() {
            return this.appointment.calendar_item_participations.map(calendarItemParticipation => {
                const participation = this.paymentStatusses.find(p => String(p.id) === calendarItemParticipation.id);

                return {
                    appointmentParticipation: calendarItemParticipation,
                    address: this.makeAddress(calendarItemParticipation.patient.address),
                    paymentParticipation: participation ? {
                        ...participation,
                        chronic: participation.chronic ? this.$t('base.yes') : this.$t('base.no'),
                        total_amount: this.$n(participation.total_amount / 100, 'currency'),
                        paid_by: participation.paid_by ? this.$t(`patient.participation_payment_status.values.paid-by-${S(participation.paid_by).underscore().toString()}`) : null,
                        _paid_by: participation.paid_by,
                        status: participation.status ? this.$t(`financial.enums.master_financial_status.${participation.status}`) : null,
                        status_color: participation.status ? MasterFinancialStatusColors[participation.status] : null,
                        private_invoicing_details: participation.private_invoicing_details ? {
                            ...participation.private_invoicing_details,
                            invoiced_to_patient: this.$n(participation.private_invoicing_details.invoiced_to_patient / 100, 'currency'),
                            to_be_invoiced_to_patient: this.$n(participation.private_invoicing_details.to_be_invoiced_to_patient / 100, 'currency'),
                        } : null,
                        insurance_details: participation.insurance_details?.map(insuranceDetail => {
                            return {
                                ...insuranceDetail,
                                reimbursement_total: this.formatReimbursement(insuranceDetail.reimbursement_total, insuranceDetail.reimbursement_unit, insuranceDetail.reimbursement_is_unlimited),
                                reimbursement_used_elsewhere: this.formatReimbursement(insuranceDetail.reimbursement_used_elsewhere, insuranceDetail.reimbursement_unit),
                                reimbursement_up_to_and_including_today: this.formatReimbursement(insuranceDetail.reimbursement_up_to_and_including_today, insuranceDetail.reimbursement_unit),
                                reimbursement_planned: this.formatReimbursement(insuranceDetail.reimbursement_planned, insuranceDetail.reimbursement_unit),
                                reimbursement_left: this.formatReimbursement(insuranceDetail.reimbursement_left, insuranceDetail.reimbursement_unit, insuranceDetail.reimbursement_is_unlimited),
                            };
                        }),
                    } : null,
                };
            });
        },

        noteHasChanged() {
            // Show action buttons if note has been changed
            return this.note !== this.event.note;
        },
    },

    watch: {
        event: {
            async handler(newValue, oldValue) {
                await this.fetchAppointmentInfo(newValue.id, !oldValue || newValue.id !== oldValue.id);
                this.note = this.appointment.note;
            },
            immediate: true,
        },
    },

    mounted() {
        $(this.$el).contextMenu({
            selector: '.js-participation-actions',
            trigger: 'none',
            build: ($trigger, e) => {
                const id = String($(e.currentTarget).data('participation'));
                const participation = this.paymentStatusses.find(p => String(p.id) === id);
                const isGroupAppointment = this.paymentStatusses.length > 1;

                const invoiceAction = {
                    name: CalendarItemOption.INVOICE,
                    label: this.$t('calendar.context-menu.invoice'),
                    icon: 'invoice',
                    disabled: !participation.invoice_can_be_printed,
                };

                let actions = [
                        { name: CalendarItemOption.OPEN, label: this.$t('calendar.context-menu.open'), icon: 'appointment-subject' },
                ];

                if (this.isService) {
                    if (AccessRights.hasAccessTo(AccessRight.PATIENT_GENERAL)) {
                        actions.push({
                            name: CalendarItemOption.EDIT_PATIENT,
                            label: this.$t('calendar.context-menu.edit-patient'),
                            icon: 'patient',
                        });
                    }

                    if (AccessRights.hasAccessTo(AccessRight.DECLARATIONS)) {
                        actions.push(invoiceAction);
                    }
                }

                if (this.isTreatment) {
                    if (AccessRights.hasAccessTo(AccessRight.FYSIOROADMAP_CONTACT_MOMENTS)) {
                        actions.push({
                            name: CalendarItemOption.EDIT_CONTACT_MOMENT,
                            disabled: !participation.module,
                            label: this.$t('calendar.context-menu.edit-contact-moment'),
                            icon: 'contact-moment',
                        });
                    }

                    const groupedActions = [];
                    if (AccessRights.hasAccessTo(AccessRight.PATIENT_GENERAL)) {
                        groupedActions.push({
                            name: CalendarItemOption.EDIT_PATIENT,
                            label: this.$t('calendar.context-menu.edit-patient'),
                            icon: 'patient',
                        });
                        groupedActions.push({
                            name: CalendarItemOption.EDIT_PATIENT_POLICY,
                            label: this.$t('calendar.context-menu.edit-patient-policy'),
                            icon: 'insurer',
                        });
                        groupedActions.push({
                            name: CalendarItemOption.SHOW_PATIENT_POLICY,
                            label: this.$t('calendar.context-menu.show-patient-policy'),
                            icon: 'insurer',
                        });
                        groupedActions.push({
                            name: CalendarItemOption.COV,
                            label: this.$t('calendar.context-menu.cov'),
                            icon: 'patient',
                        });
                    }
                    if (AccessRights.hasAccessTo(AccessRight.FYSIOROADMAP_INDICATION_GENERAL) || AccessRights.hasAccessTo(AccessRight.FYSIOROADMAP_INDICATION_FINANCIAL)) {
                        groupedActions.push({
                            name: CalendarItemOption.EDIT_INDICATION_HISTORY,
                            disabled: !participation.module,
                            label: this.$t('calendar.context-menu.edit-indication-history'),
                            icon: 'indication',
                        });
                    }
                    if (AccessRights.hasAccessTo(AccessRight.FYSIOROADMAP)) {
                        groupedActions.push({
                            name: CalendarItemOption.OPEN_FYSIOROADMAP,
                            label: this.$t('calendar.context-menu.open-fysioroadmap'),
                            icon: 'fysioroadmap',
                        });
                    }
                    groupedActions.push({
                        name: CalendarItemOption.UNFULFILLED,
                        disabled: !this.event.is_unfulfillable || isGroupAppointment,
                        label: this.$t('calendar.context-menu.unfulfilled'),
                        icon: 'unfulfilled',
                    });

                    if (AccessRights.hasAccessTo(AccessRight.DECLARATIONS)) {
                        groupedActions.push({
                            name: CalendarItemOption.OPEN_PAYMENT_STATUS,
                            disabled: !participation.module,
                            label: this.$t('calendar.context-menu.open-payment-status'),
                            icon: 'masterfiles-financial',
                        });
                    }

                    if (AccessRights.hasAccessTo(AccessRight.DECLARATIONS)) {
                        actions.push(invoiceAction);
                    }

                    if (groupedActions.length) {
                        actions.push({ type: 'break' });
                        actions = actions.concat(groupedActions);
                    }

                    if (AccessRights.hasAccessTo(AccessRight.DASHBOARD_MESSAGING)) {
                        actions.push({ type: 'break' });
                        actions.push({
                            name: CalendarItemOption.CREATE_MESSAGE,
                            label: this.$t('calendar.context-menu.create-message'),
                            icon: 'message',
                        });
                        actions.push({
                            name: CalendarItemOption.CREATE_ACTION,
                            label: this.$t('calendar.context-menu.create-action'),
                            icon: 'action',
                        });
                    }
                }

                if (this.isSubscription) {
                    if (AccessRights.hasAccessTo(AccessRight.PATIENT_GENERAL)) {
                        actions.push({
                            name: CalendarItemOption.EDIT_PATIENT,
                            label: this.$t('calendar.context-menu.edit-patient'),
                            icon: 'patient',
                        });
                    }

                    if (AccessRights.hasAccessTo(AccessRight.PATIENT_SUBSCRIPTIONS)) {
                        actions.push({
                            name: CalendarItemOption.EDIT_PATIENT_SUBSCRIPTION,
                            disabled: !participation.subscription_item_data_id,
                            label: this.$t('calendar.context-menu.edit-patient-subscription'),
                            icon: 'subscription',
                        });
                    }

                    if (AccessRights.hasAccessTo(AccessRight.DECLARATIONS)) {
                        if (actions.length) {
                            actions.push({ type: 'break' });
                        }

                        actions.push({
                            name: CalendarItemOption.OPEN_PAYMENT_STATUS,
                            label: this.$t('calendar.context-menu.open-payment-status'),
                            icon: 'masterfiles-financial',
                        });
                    }
                }

                return {
                    zIndex: 10,
                    items: buildContextMenuItemsFromActions(actions, action => this.participationAction(participation, action.name)),
                };
            },
        });

        const modalClosedCallback = () => {
            this.fetchAppointmentInfo(this.event.id, false);
        };
        ModalManager.$on('modalClosed', modalClosedCallback);
        this.$once('hook:beforeDestroy', () => {
            ModalManager.$off('modalClosed', modalClosedCallback);
        });
    },

    methods: {
        async fetchAppointmentInfo(calendarItemId, showLoader = true) {
            this.loading = showLoader;

            return Promise.all(
                [
                    jsonApi.find('calendar_item', calendarItemId).then(response => response.data),
                    this.fetchPaymentStatus(true),
                    // Make sure the loader is shown at least 300 ms to prevent a "Flash Of Loading Content"™
                    new Promise(resolve => setTimeout(() => resolve(), 300)),
                ]
            ).then(([appointment]) => {
                this.appointment = appointment;
            }).catch(errors => DialogManager.errors(errors)).finally(() => {
                this.loading = false;
            });
        },

        async refreshPaymentStatus() {
            await axios.post(`/calendar/appointment/${this.event.id}/forget-payment-status`);

            this.paymentStatusFetched = false;
            this.paymentStatusCachedAt = null;

            await this.fetchPaymentStatus(false);
        },

        async fetchPaymentStatus(fromCacheOnly) {
            if (this.paymentStatusFetched || this.paymentStatusLoading) {
                return;
            }

            this.paymentStatusLoading = true;

            try {
                const response = (await axios.get(`/calendar/appointment/${this.event.id}/payment-status`, {
                    params: {
                        'from-cache-only': fromCacheOnly,
                    },
                })).data;

                this.paymentStatusses = response.data;
                this.paymentStatusCachedAt = response.from_cache ? response.cached_at : null;
                this.paymentStatusFetched = true;
            } catch (errors) {
                if (fromCacheOnly && errors.response?.status === 404) {
                    // cache was empty, no need to show an error
                    return;
                }
                await DialogManager.errors(errors);
            } finally {
                this.paymentStatusLoading = false;
            }
        },

        formatReimbursement(reimbursement, reimbursementUnit, showUnlimited = false) {
            if (showUnlimited) {
                return this.$t('calendar.appointment.reimbursement-unlimited');
            }

            switch (reimbursementUnit) {
                case ReimbursementUnit.AMOUNT:
                    return this.$n(reimbursement / 100, 'currency');
                case ReimbursementUnit.MINUTES:
                    return this.$t('patient.fields.policies.unit-minutes', { amount: reimbursement });
                case ReimbursementUnit.TREATMENTS:
                    return this.$t('patient.fields.policies.unit-treatments', { amount: reimbursement });
                default:
                    return String(reimbursement);
            }
        },

        async triggerParticipationActionContextmenu(event) {
            await this.fetchPaymentStatus(false);

            $(event.target).contextMenu({ x: event.pageX, y: event.pageY });
        },

        async participationAction(participation, name) {
            switch (name) {
                case CalendarItemOption.OPEN:
                    ModalManager.openModal(`/calendar/appointment/${this.event.id}/edit`);
                    break;
                case CalendarItemOption.EDIT_CONTACT_MOMENT:
                    ModalManager.openModal(`/fysioroadmap/${participation.patient_id}/${participation.indication_history_id}/contact_moments/${participation.id}/edit?show_notes=1`, undefined, undefined, 'full');
                    break;
                case CalendarItemOption.EDIT_PATIENT:
                    ModalManager.openModal(`/patient/${participation.patient_id}/general`, undefined, undefined, 'full');
                    break;
                case CalendarItemOption.EDIT_PATIENT_POLICY:
                    ModalManager.openModal(`/patient/${participation.patient_id}/policies`, undefined, undefined, 'full');
                    break;
                case CalendarItemOption.EDIT_PATIENT_SUBSCRIPTION:
                    ModalManager.openModal(`/patient/${participation.patient_id}/subscriptions/${participation.subscription_item_data_id}/edit`, undefined, undefined, 'full');
                    break;
                case CalendarItemOption.SHOW_PATIENT_POLICY:
                    ModalManager.openModal(`/patient/${participation.patient_id}/policy/summary`, undefined, undefined, 'full');
                    break;
                case CalendarItemOption.EDIT_INDICATION_HISTORY:
                    ModalManager.openModal(`/fysioroadmap/${participation.patient_id}/${participation.indication_history_id}/indication`, undefined, undefined, 'full');
                    break;
                case CalendarItemOption.OPEN_FYSIOROADMAP:
                    window.location.href = `/fysioroadmap/${participation.patient_id}/${participation.indication_history_id}/dashboard?show_notes=1`;
                    break;
                case CalendarItemOption.OPEN_PAYMENT_STATUS:
                    if (this.isTreatment) {
                        ModalManager.openModal(`/patient/${participation.patient_id}/participation_payment_status?type=treatment&indication_history_id=${participation.indication_history_id}`, undefined, undefined, 'full');
                    }

                    if (this.isSubscription) {
                        ModalManager.openModal(`/patient/${participation.patient_id}/participation_payment_status?type=subscription`, undefined, undefined, 'full');
                    }

                    if (this.isService) {
                        ModalManager.openModal(`/patient/${participation.patient_id}/participation_payment_status?type=service`, undefined, undefined, 'full');
                    }

                    break;
                case CalendarItemOption.CREATE_MESSAGE:
                    ModalManager.openModal(`/dashboard/sent-messages/create?indication_history_id=${participation.indication_history_id}`);
                    break;
                case CalendarItemOption.CREATE_ACTION:
                    ModalManager.openModal(`/dashboard/sent-actions/create?indication_history_id=${participation.indication_history_id}`);
                    break;
                case CalendarItemOption.UNFULFILLED:
                    jsonApi.update('calendar_item_with_performance_code_resource', { id: this.event.id, is_unfulfilled: true })
                        .catch(errors => DialogManager.errors(errors))
                        .then(() => Promise.all([
                            this.fetchAppointmentInfo(this.event.id, false),
                            this.$store.dispatch('calendar/fetchEvents'),
                        ]));
                    break;
                case CalendarItemOption.INVOICE: {
                    const job = await invoiceAndPrint(participation.id, this.isTreatment ? 'treatment' : 'service');
                    await this.processJob(job);
                    await Promise.all([
                        this.fetchAppointmentInfo(this.event.id, false),
                        this.$store.dispatch('calendar/fetchEvents'),
                    ]);
                    break;
                }
                case CalendarItemOption.COV:
                    doCovCheck(participation.patient_id).then(() => Promise.all([
                        this.fetchAppointmentInfo(this.event.id, false),
                        this.$store.dispatch('calendar/fetchEvents'),
                    ]));
                    break;
            }
        },

        saveNote() {
            // When POST- or PATCH-ing the item the API requires the type to be suffixed with '_resource'.
            jsonApi.update(this.appointment.type + '_resource', { id: this.appointment.id, note: this.note }, { force: 'true' })
                .catch(errors => DialogManager.errors(errors))
                .then(() => Promise.all([
                    this.fetchAppointmentInfo(this.event.id, false),
                    this.$store.dispatch('calendar/fetchEvents'),
                ]));
        },

        resetNote() {
            this.note = this.event.note;
        },

        makeAddress(address) {
            const lines = [];

            if (address.street) {
                lines.push(`${address.street || ''} ${address.house_number || ''}${address.house_number_suffix || ''}`);
            }

            if (address.postal_code && address.city) {
                lines.push(`${address.postal_code} ${address.city}`);
            }

            return lines.map(line => line.trim()).filter(line => Boolean(line));
        },
    },
};
</script>

<style lang="scss">
.appointment-info__panel {
  .panel__content {
    display: flex;
    padding: 0;
  }
}

.appointment-info__note {
  border-radius: 0;
  flex-grow: 1;
  margin: -1px;
}

.appointment-info__outdated-warning {
    align-items: center;
    background-color: $color__attention;
    display: flex;
    justify-content: space-between;
    margin-top: rhythm(1.5);
    padding: rhythm(1);

    p {
        margin: 0;
    }
}
</style>
