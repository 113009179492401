export default {
    name: 'calendar_note',
    definition: {
        description: '',
        date: '',
        employees: {
            jsonApi: 'hasMany',
            type: 'employee',
        },
    },
    options: {
        collectionPath: 'calendar_notes',
    },
};
