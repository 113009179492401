// @vue/component
import jsonApi from '../../json-api-client';
import apiUrl from '../../services/apiUrl';
import JobPendingDialog from '../../components/JobPendingDialog.vue';
import ModalManager from '../../services/modalManager';

const fetchJob = async(jobId) => (await jsonApi.axios.get(apiUrl(`/job/${jobId}`))).data;
const jobIsCompleted = (job) => job.attributes.status === 'Completed';
const jobIsFailed = (job) => job.attributes.status === 'Failed';

const calculateTimeout = (retryCount) => {
    if (retryCount < 8) {
        return 250;
    } else if (retryCount < 12) {
        return 500;
    } else if (retryCount < 16) {
        return 1000;
    } else {
        return 2000;
    }
};

export default {
    data() {
        return {
            jobDialogShown: false,
        };
    },

    methods: {
        processJob(job) {
            return new Promise((resolve, reject) => {
                const start = Date.now();

                const poll = async(retryCount = 0) => {
                    const jobResponse = await fetchJob(job.id);
                    const jobData = jobResponse.data;

                    if (jobIsCompleted(jobData)) {
                        await this.onJobComplete(job);

                        resolve();
                    } else if (jobIsFailed(jobData)) {
                        if (this.jobDialogShown) {
                            this.closeDialog();
                        }

                        reject(new Error(
                            jobResponse.included?.find((included) => included.type === 'error')?.attributes?.detail));
                    } else {
                        this.$store.commit('core/setPendingJobMessage', jobData.attributes.message || '');

                        if (!this.jobDialogShown && (Date.now() - start) > window.frm.state.jobDialogThresholdSeconds * 1000) {
                            ModalManager.openComponentInModal(JobPendingDialog, {}, {}, 'small', false).then();
                            this.jobDialogShown = true;
                        }

                        setTimeout(() => poll(retryCount + 1), calculateTimeout(retryCount));
                    }
                };

                poll().then();
            });
        },

        closeDialog() {
            ModalManager.closeModal();
            this.jobDialogShown = false;
        },

        async onJobComplete(job) {
            if (this.jobDialogShown) {
                this.closeDialog();
            }

            try {
                await this.handleUrl(job.on_complete, job.type, 'GET', job.target);
            } catch (error) {
                await this.handleErrorResponse(error);
            }
        },
    },
};
